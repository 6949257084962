<template>
  <div class="Statistics">
    <div class="Statistics-header">
      <div class="Statistics-header-left">海丰电商</div>
      <div class="Statistics-header-right">
        <a :href="openScreenOverviewUrl" target="__blank">前往数据大屏(总览)</a>
        <a :href="openScreen9610Url" target="__blank">前往数据大屏(9610)</a> | 
        <router-link to="/">返回官网</router-link>
      </div>
    </div>
    <div class="Statistics-container">
      <div class="Statistics-container-left">
        <div class="title">
          <div class="left"></div>
          <div class="right">
            <p>数据分析</p>
            <p>Data analysis</p>
          </div>
        </div>
          <el-menu
            :default-active="curActive"
            :router="true"
          >
            <el-menu-item index="/statistics/today">
              <span slot="title">今日统计数据</span>
            </el-menu-item>
            <el-menu-item index="/statistics/business">
              <span slot="title">业务类型申报量分析</span>
            </el-menu-item>
            <el-menu-item index="/statistics/client">
              <span slot="title">客户业务申报量分析</span>
            </el-menu-item>
            <el-menu-item index="/statistics/country">
              <span slot="title">目的国占比分析</span>
            </el-menu-item>
            <el-menu-item index="/statistics/cd">
              <span slot="title">申报关区占比分析</span>
            </el-menu-item>
          </el-menu>
      </div>
      <div class="Statistics-container-right">
        <router-view />
      </div>
    </div>
    <!-- <div class="express-entrance">
      <router-link to="/statistics/lss">数据大屏</router-link>
      <router-link to="/statistics/business">业务类型申报量/贸易额占比</router-link>
      <router-link to="/statistics/client">客户业务申报量</router-link>
      <router-link to="/statistics/country">目的国占比</router-link>
      <router-link to="/statistics/cd">申报关区占比</router-link>
    </div> -->
  </div>
</template>

<script>
export default {
    name: 'Statistics',
    computed: {
      openScreenOverviewUrl() {
        return `${location.origin}/screen/lss`; 
      },
      openScreen9610Url() {
        return `${location.origin}/screen/9610`; 
      },
      curActive() {
        return this.$route.path;
      }
    },
  }
</script>

<style lang="scss" scoped>
.Statistics{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &-header{
    width: 100%;
    height: 48px;
    line-height: 48px;
    padding: 0 80px 0 30px;
    background-color: #3484FB;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    &-left{
      font-size: 18px;
      color: #fff;
    }
    &-right{
      display: flex;
      color: #fff;
      a{
        margin: 0 20px;
        color: #fff;
        text-indent: 2em;
      }
      a:first-child{
        background: url("~@/assets/images/dashboard/icon-screen.svg") left center no-repeat;
      }
      a:last-child{
        background: url("~@/assets/images/dashboard/icon-portal.svg") left center no-repeat;
      }
    }
  }
  &-container{
    flex: 1;
    height: calc(100vh - 48px);
    display: flex;
    &-left{
      width: 208px;
      box-shadow: 2px 0px 8px 0px rgba(0,0,0,0.05);
      .title{
        width: 100%;
        display: flex;
        padding: 35px 30px;
        border-bottom: 1px solid #F2F2F2;
        .left{
          width: 32px;
          height: 32px;
          background: url("~@/assets/images/dashboard/icon-title.svg") center center no-repeat;
        }
        .right{
          margin-left: 15px;
          p:firxt-child{
            color: #525252;
          }
          p:last-child{
            margin-top: 3px;
            font-size: 12px;
            color: #909097;
          }
        }
      }
      ::v-deep(.el-menu){
        width: 100%;
        height: 100%;
        margin-top: 8px;
        border-right: none;
        li.el-menu-item{
          padding-left: 30px!important;
          border-left: 3px solid transparent;
          span{
            color: #4C4C4C;
          }
          &:hover{
            background-color: rgba(0,90,239,0.05);
          }
          &.is-active{
            border-left: 3px solid #005AEF;
            background-color: rgba(0,90,239,0.05);
            span{
              color: #525252;
            }
          }
        }
      }
    }
    &-right{
      flex: 1;
      height: 100%;
      // overflow-y: auto;
    }
  }
  .express-entrance{
    display: flex;
    justify-content: center;
    a{
      width: 250px;
      // height: 120px;
      // line-height: 80px;
      padding: 40px 20px;
      margin-left: 30px;
      font-size: 26px;
      text-align: center;
      color: #fff;
      background-color: rgba(24, 122, 205, 0.8);
      box-sizing: border-box;
    }
  }
}
</style>
<style lang="scss">
.dashboard-container{
  display: flex;
  flex-flow: column;
  height: 100%;
  background-color: #F5F7F8;
  &-main{
    flex: 1;
    padding: 24px 24px 80px 24px;
    box-sizing: border-box;
    overflow-y: auto;
  }
}
.statistics-container{
  width: 100%;
  height: 300px;
  display: flex;
  justify-content:space-between;
  &-item{
    width: calc(50% - 12px);
    height: 100%;
    padding: 20px 20px;
    background-color: #fff;
    box-sizing: border-box;
    position: relative;
    .search{
      width: 100px;
      position: absolute;
      top: 20px;
      right: 24px;
    }
  }
}
.statistics_title{
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  font-weight: 700;
  text-indent: 1.5em;
  background: url('~@/assets/images/dataScreen/icon_chart.svg');
  background-position: 0% 47%;
  background-repeat: no-repeat;
  .sub_title{
    font-size: 16px;
    font-weight: 700;
    color: #3484FB;
  }
}
.chart-container{
  width: 100%;
  height: 100%;
}
</style>